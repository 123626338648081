<template>
<div>
  <v-row class="ma-0 pb-5 px-2">
    <v-col
        cols="6"
        md="10"
    >
      <h1>
        Liste des Differente Caisse
      </h1>
    </v-col>
    <v-col
        cols="6"
        md="6"
    >
      <h4>
        Nombre de Caisse: {{listFund.length}}
      </h4>
    </v-col>
  </v-row>
  <v-row
      class="match-height"
      style="margin-bottom: 2%;"
  >
    <v-col
        :key="fund.id"
        cols="12"
        v-for="fund in listFund"
        md="6"
    >
      <statistics-card-vertical
          color="success"
          :icon="icon"
          :statistics="fund.fund_amount+' FCFA'"
          :stat-title="fund.fund_name"
          :subtitle="fund.fund_type.fund_typ_name"
          :hasDetail="true"
          :detailLink="fund.id"
      ></statistics-card-vertical>
    </v-col>
  </v-row>
</div>
</template>

<script>
import { mdiBank } from '@mdi/js'
import * as components from 'vuetify'

// eslint-disable-next-line import/extensions
import StatisticsCardVertical from '@/views/treasury/StatisticsCardVertical'

export default {
  name:'Fund',
  components: {
    StatisticsCardVertical,
    mdiBank,
  },
  data() {
    return {
      listFund: [],
      icon: mdiBank,
    }
  },
  mounted() {
    this.loadFund()
  },
  methods: {
    loadFund() {
      this.$axios.get(this.$endpoint.LoadFund).then(rp => {
        this.listFund = rp.data
      })
    },
  },
}
</script>

<style scoped>

</style>
